<template>
  <section id="dashboard" class="bgColor5">
    <search-record-list />
  </section>
</template>

<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import searchRecordList from "./dash/searchRecordList.vue";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BButton,
    searchRecordList,
  },
  data() {
    return {
    }
  },
  methods: {
  },
  created() {
  },
};
</script>

<style lang="scss" scoped></style>
