import { ref, watch, computed, onMounted } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useSearchList() {
  // Use toast
  const toast = useToast();

  const refSearchListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "created_at", label: "搜尋日期", sortable: true },
    // { key: "search_key_words", label: "項目 "},
    // { key: "client.name_cn",stickyColumn: true, label: "客戶姓名（中文）" },
    // { key: "client.name_en", label: "客戶姓名（英文）" },
    { key: "client.id_number", label: "客戶身份證 " },
    { key: "actions", label: "詳情" },
    { key: "user.contact_name", label: "搜尋者" },
  ];
  const perPage = ref(10);
  const totalSearchs = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("created_at");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refSearchListTable.value
      ? refSearchListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSearchs.value,
    };
  });


  const refetchData = () => {
    refSearchListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery],
    () => {
      refetchData();
    }
  );

  const fetchSearchs = (ctx, callback) => {
    store
      .dispatch("app/fetchDashboardSearchRecord", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        const { search_records, total } = response.data;
        callback(search_records);
        totalSearchs.value = total;
      })
      .catch((err) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching search list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };  
  
  const suppliersInfo = ref({})
  const fetchSuppliersInfo = (ctx, callback) => {
    store
      .dispatch("app/fetchSuppliersInfo")
      .then((response) => {
        const { supplier } = response.data;
        suppliersInfo.value = supplier
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "獲取貸款清單時出錯",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }; 

  const calUploadRate = computed(() => {
    const cal =  Number(suppliersInfo.value.valid_client_count) / Number(suppliersInfo.value.account_records_count)
    cal > 1 ? 1 : cal
    return cal * 100
  })

  const calNoUpload = computed(() => {
    // console.log(Number(suppliersInfo.value.client_count), Number(suppliersInfo.value.valid_client_count), Number(suppliersInfo.value.valid_client_count))
    const cal = Number(suppliersInfo.value.client_count) - Number(suppliersInfo.value.actual_valid_client_count)
    // + Number(suppliersInfo.value.contract_count)
    return cal > 0 ? cal : 0
  })

  onMounted(() => {
    fetchSuppliersInfo();
  });

  return {
    fetchSearchs,
    tableColumns,
    perPage,
    currentPage,
    totalSearchs,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSearchListTable,
    ability,
    refetchData,

    fetchSuppliersInfo,
    suppliersInfo,
    calUploadRate,
    calNoUpload
  };
}
