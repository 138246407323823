<template>
  <!-- modal-search-form-->
  <div>
    <modal-loan-edit :loan-data="load_data" :inEdit="inEdit"></modal-loan-edit>
    <b-modal
      id="modal-search-form"
      cancel-variant="outline-secondary"
      centered
      hide-footer
      size="lg"
      :ok-disabled="false"
      headerClass="px-2 py-2 border-bottom bgWhite"
      footerClass="px-3 py-2 border-top bgWhite"
    >
      <template #modal-header="{ close }">
        <h3 class="m-0">{{ modalTitle }}</h3>
        <a @click="close()">
          <font-awesome-icon style="font-size: 20px" icon="fas fa-times" />
        </a>
      </template>

      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-form class="" @submit.prevent="handleSubmit(onSubmit)">
            <b-row>
              <!-- contact_name -->
              <b-col cols="12" md="6">
                <b-form-group
                  label="中文名稱："
                  label-for="search_name_cn"
                  label-cols-md="3"
                  :class="isNameRequired ? 'required' : ''"
                >
                  <b-form-input
                    id="search_name_cn"
                    v-model="searchFormData.name_cn"
                    placeholder="輸入中文名稱"
                  />
                  <div class="error-text" v-if="!isNameRequired">
                    中文名稱 或 英文名稱 只需輸入其中一項
                  </div>
                </b-form-group>
              </b-col>

              <!-- contact_name -->
              <b-col cols="12" md="6">
                <b-form-group
                  label="英文名稱："
                  label-for="search_name_en"
                  label-cols-md="3"
                  :class="isNameRequired ? 'required' : ''"
                >
                  <b-form-input
                    id="search_name_en"
                    v-model="searchFormData.name_en"
                    placeholder="輸入英文名稱"
                  />
                  <div class="error-text" v-if="!isNameRequired">
                    中文名稱 或 英文名稱 只需輸入其中一項
                  </div>
                </b-form-group>
              </b-col>
              <hr />

              <!-- id_number -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="身份證號碼"
                  rules="required"
                >
                  <b-form-group
                    label="身份證號碼："
                    label-for="search_id_number"
                    label-cols-md="3"
                    class="required"
                  >
                    <b-form-input
                      id="search_id_number"
                      v-model="searchFormData.id_number"
                      @input="IsHKID(searchFormData.id_number)"
                      :state="getValidationState(validationContext)"
                      placeholder="輸入身份證號碼"
                    />
                    <b-form-invalid-feedback :force-show="true">
                      {{ idError ? idError : validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- loan_date -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="貸款日"
                  rules="required"
                >
                  <b-form-group
                    label="貸款日："
                    label-for="search_loan_date"
                    label-cols-md="3"
                    class="required"
                  >
                    <flat-pickr
                      v-model="searchFormData.loan_date"
                      class="form-control"
                      :config="{
                        altInput: true,
                        altFormat: 'Y-m-d',
                        allowInput: true,
                        dateFormat: 'Y-m-d',
                        maxDate: $store.state.app.maxDate,
                      }"
                      placeholder="輸入貸款日"
                      @input="calcLoanCloseDate"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <hr />
              <!-- amount -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="貸款額"
                  rules="required"
                >
                  <b-form-group
                    label="貸款額"
                    label-for="loan_amount"
                    label-cols-md="3"
                    class="required"
                  >
                    <b-form-input
                      id="loan_amount"
                      v-model="searchFormData.amount"
                      type="number"
                      :state="getValidationState(validationContext)"
                      placeholder="輸入貸款額"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- period -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="期數"
                  rules="required"
                >
                  <b-form-group
                    label="期數："
                    label-for="search_period"
                    label-cols-md="3"
                    class="required"
                  >
                    <b-form-input
                      id="search_period"
                      v-model="searchFormData.period"
                      :state="getValidationState(validationContext)"
                      type="number"
                      placeholder="輸入期數"
                      @keyup="calcLoanCloseDate"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <hr />

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="月供/半月供"
                  rules="required"
                >
                  <b-form-group
                    label="月供/半月供："
                    :label-for="`search_is_monthly`"
                    label-cols-md="3"
                    class="required"
                  >
                    <div class="form-col-select">
                      <v-select
                        v-model="searchFormData.is_monthly"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="loan_is_monthly"
                        :clearable="false"
                        label="name"
                        :reduce="(option) => option.id"
                        placeholder="輸入月供/半月供"
                        @input="calcLoanCloseDate"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- min_paid -->
              <b-col cols="12" md="6">
                <b-form-group
                  label="每月/最低供款："
                  label-for="search_min_paid"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="search_min_paid"
                    type="number"
                    v-model="searchFormData.min_paid"
                    placeholder="輸入每月/最低供款"
                  />
                </b-form-group>
              </b-col>

              <!-- remark -->
              <b-col cols="12" md="6">
                <b-form-group
                  label="客戶備註："
                  label-for="search_remark"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="search_remark"
                    v-model="searchFormData.remark"
                    placeholder="輸入客戶備註"
                  />
                </b-form-group>
              </b-col>
              <hr />
            </b-row>
            <div
              class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-sm-2 mobile-w100"
                type="submit"
              >
                <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
                提交
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hideModal"
                class="mt-1 mt-sm-0 mobile-w100"
              >
                取消
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, watch, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import store from "@/store";
import loanStoreModule from "@/views/loan/loanStoreModule";
import ModalLoanEdit from "../../../loan/modal/ModalLoanEdit.vue";
import { required, email } from "@validations";
export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BOverlay,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ModalLoanEdit,
  },
  data() {
    return {
      required,
      email,
      confirm_p: "",
      loading: false,
      load_data: {},
      isCalc: false,
      inEdit: 2,
      loan_is_monthly: [
        { id: 1, name: "月供" },
        { id: 0, name: "半月供" },
      ],
    };
  },
  directives: {
    Ripple,
  },
  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    hkid: {
      type: String,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("modal-search-form");
    },
    calcLoanCloseDate() {
      if (
        this.searchFormData.loan_date &&
        this.searchFormData.period &&
        (this.searchFormData.is_monthly ||
          this.searchFormData.is_monthly == 0) &&
        !this.isCalc
      ) {
        const data = {
          loan_date: this.searchFormData.loan_date,
          period: this.searchFormData.period,
          is_monthly: this.searchFormData.is_monthly,
        };
        this.isCalc = true;
        store
          .dispatch("app-loan/getContractEndDate", data)
          .then((response) => {
            this.searchFormData.first_pay_date =
              response.data.data.paid_date[0];
            this.searchFormData.contract_end_date =
              response.data.data.contract_end_date;
            this.searchFormData.pay_date =
              response.data.data.paid_date.join(" , ");
            this.isCalc = false;
          })
          .catch((error) => {
            this.isCalc = false;

            this.$swal({
              text: JSON.stringify(error.response.data.message),
              icon: "error",
              cancelButtonText: "取消",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              showClass: {
                popup: "animate__animated animate__bounceIn",
              },
              buttonsStyling: false,
            });
          });
      }
    },
    onSubmit() {
      if (this.idError || !this.isNameRequired) {
        return;
      }
      // console.log('searchFormData',this.searchFormData)
      this.$swal({
        title: `你確定嗎？`,
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;

          this.$store
            .dispatch("app/fetchLoanSearchForm", this.searchFormData)
            .then((response) => {
              this.loading = false;
              const that = this;

              this.$emit("refetch-data");
              this.$store.dispatch("app/fetchCoinsBalance");

              this.$swal({
                text: response.data.message,
                showCancelButton: true,
                confirmButtonText: "確定",
                cancelButtonText: "貸款記錄",
                icon: "success",
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                customClass: {
                  confirmButton: "btn btn-primary",
                  cancelButton: "btn btn-outline-primary ml-1",
                },
                buttonsStyling: false,
              }).then(function (dismiss) {
                if (dismiss.dismiss == "cancel") {
                  that.load_data = response.data.loan_data;
                  that.$bvModal.show(`modal-loan-edit${that.inEdit}`);
                }
              });
              this.hideModal();
            })
            .catch((error) => {
              this.loading = false;
              this.hideModal();
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  computed: {
    isNameRequired() {
      if (!this.searchFormData.name_cn && !this.searchFormData.name_en) {
        return false;
      } else {
        return true;
      }
    },
  },
  setup(props) {
    const loan_STORE_MODULE_NAME = "app-loan";

    // Register module
    if (!store.hasModule(loan_STORE_MODULE_NAME))
      store.registerModule(loan_STORE_MODULE_NAME, loanStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(loan_STORE_MODULE_NAME))
        store.unregisterModule(loan_STORE_MODULE_NAME);
    });

    const searchFormData = ref({
      loan_date: new Date(),
      first_pay_date: "",
      contract_end_date: "",
      pay_date: "",
    });
    const resetsearchFormData = () => {
      searchFormData.value = JSON.parse(JSON.stringify({}));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetsearchFormData);

    const idError = ref(null);

    const IsHKID = (str) => {
      var strValidChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

      // basic check length
      if (str.length < 8) {
        idError.value = "身份證號碼" + str + "不正確";
        return false;
      }

      // handling bracket
      if (
        str.charAt(str.length - 3) == "(" &&
        str.charAt(str.length - 1) == ")"
      )
        str = str.substring(0, str.length - 3) + str.charAt(str.length - 2);

      // convert to upper case
      str = str.toUpperCase();

      // regular expression to check pattern and split
      var hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;

      var matchArray = str.match(hkidPat);

      // not match, return false
      if (matchArray == null) {
        idError.value = "身份證號碼" + str + "不正確";
        return;
      }

      // the character part, numeric part and check digit part
      var charPart = matchArray[1];
      var numPart = matchArray[2];
      var checkDigit = matchArray[3];

      // calculate the checksum for character part
      var checkSum = 0;
      if (charPart.length == 2) {
        checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
        checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
      } else {
        checkSum += 9 * 36;
        checkSum += 8 * (10 + strValidChars.indexOf(charPart));
      }

      // calculate the checksum for numeric part
      for (var i = 0, j = 7; i < numPart.length; i++, j--)
        checkSum += j * numPart.charAt(i);

      // verify the check digit
      var remaining = checkSum % 11;
      var verify = remaining == 0 ? 0 : 11 - remaining;

      if (verify == checkDigit || (verify == 10 && checkDigit == "A")) {
        idError.value = "";
      } else {
        idError.value = "身份證號碼" + str + "不正確";
      }
    };

    watch(
      () => props.hkid,
      (newValue) => {
        searchFormData.value = { ...searchFormData.value, id_number: newValue };
        IsHKID(newValue);
        immediate: true;
      }
    );

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      searchFormData,
      IsHKID,
      idError,
    };
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#modal-search-form .modal-dialog {
  width: 100%;
  max-width: 1000px;
}
</style>
