<template>
  <div>
    <b-modal
      id="modal-add-account"
      cancel-variant="outline-secondary"
      centered
      hide-footer
      size="lg"
      :ok-disabled="false"
      headerClass="px-2 py-2 border-bottom bgWhite"
      footerClass="px-3 py-2 border-top bgWhite"
    >
      <template #modal-header="{ close }">
        <h3 class="m-0">{{ isEdit ? "修改" : "新增" }}帳務記錄</h3>
        <a @click="close()">
          <font-awesome-icon style="font-size: 20px" icon="fas fa-times" />
        </a>
      </template>

      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-form class="" @submit.prevent="handleSubmit(onSubmit)">
            <b-row class="justify-content-center">
              <!-- contact_name -->
              <b-col cols="12">
                <validation-provider
                  #default="validationContext"
                  name="帳務狀態"
                  rules="required"
                >
                  <b-form-group
                    label="帳務狀態："
                    label-for="account_status"
                    label-cols-md="3"
                    class="required"
                  >
                    <b-form-radio-group
                      style="margin-top: 10px"
                      v-model="accountNewData.account_option_id"
                      name="account_status"
                      stacked
                    >
                      <b-form-radio
                        v-for="option of accountOptions"
                        :key="'account_status' + option.id"
                        :value="option.id"
                        class="mb-1"
                        >{{ option.name }}</b-form-radio
                      >
                    </b-form-radio-group>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- date -->
              <b-col cols="12">
                <b-form-group
                  label="日期："
                  label-for="account_date"
                  label-cols-md="3"
                >
                  <flat-pickr
                    id="account_date"
                    v-model="accountNewData.record_date"
                    class="form-control"
                    :config="{ 
                      altInput: true,
                      altFormat: 'Y-m-d',
                      allowInput: true,
                      dateFormat: 'Y-m-d',
                      maxDate: $store.state.app.maxDate
                    }"
                    placeholder="輸入日期"
                  />
                </b-form-group>
              </b-col>

              <!-- remark -->
              <b-col cols="12">
                <b-form-group
                  label="備註："
                  label-for="account_remark"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="account_remark"
                    v-model="accountNewData.remark"
                    placeholder="輸入備註"
                  />
                </b-form-group>
              </b-col>
              <hr />
            </b-row>
            <b-table
              v-if="accountLogs && accountLogs.length > 0"
              ref="refAccountLogsListTable"
              class="mb-1"
              :items="accountLogs"
              responsive
              :fields="tableColumns"
              show-empty
              empty-text="未找到匹配的記錄"
            >
              <template #cell(created_at)="data">
                <div class="d-flex">
                  {{ getCorrectDateTime(data.item.created_at) }}
                </div>
              </template>

              <template #cell(creator)="data">
                {{ data.item.creator.contact_name }}
              </template>

              <template #cell(changedData)="data">
                <div
                  class="d-flex logs-flex"
                  v-if="JSON.parse(data.item.changedData).account_option_id"
                >
                  <span class="title">帳務狀態</span>
                  <!-- <span class="desc">{{JSON.parse(data.item.changedData).account_option_id.old_value}}</span> -->
                  <span class="desc">
                    {{
                      accountOptions.filter(
                        (item) =>
                          item.id ==
                          JSON.parse(data.item.changedData).account_option_id
                            .old_value
                      )[0].name
                    }}
                    >
                    {{
                      accountOptions.filter(
                        (item) =>
                          item.id ==
                          JSON.parse(data.item.changedData).account_option_id
                            .new_value
                      )[0].name
                    }}</span
                  >
                </div>
                <div
                  class="d-flex logs-flex"
                  v-if="JSON.parse(data.item.changedData).record_status_id"
                >
                  <span class="title">狀態</span>
                  <span class="desc">
                    {{
                      statusOptions.filter(
                        (item) =>
                          item.id ==
                          JSON.parse(data.item.changedData).record_status_id
                            .old_value
                      )[0].name
                    }}
                    >
                    {{
                      statusOptions.filter(
                        (item) =>
                          item.id ==
                          JSON.parse(data.item.changedData).record_status_id
                            .new_value
                      )[0].name
                    }}</span
                  >
                </div>
                <div
                  class="d-flex logs-flex"
                  v-if="JSON.parse(data.item.changedData).record_date"
                >
                  <span class="title">日期</span>
                  <span class="desc">
                    {{
                      JSON.parse(data.item.changedData).record_date.old_value
                    }}
                    >
                    {{
                      JSON.parse(data.item.changedData).record_date.new_value
                    }}</span
                  >
                </div>
                <div
                  class="d-flex logs-flex"
                  v-if="JSON.parse(data.item.changedData).remark"
                >
                  <span class="title">備註</span>
                  <span class="desc">
                    {{ JSON.parse(data.item.changedData).remark.old_value }}
                    >
                    {{
                      JSON.parse(data.item.changedData).remark.new_value
                    }}</span
                  >
                </div>
              </template>
            </b-table>
            <hr v-if="accountLogs && accountLogs.length > 0" />
            <div
              class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-sm-2 mobile-w100"
                type="submit"
              >
                <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
                提交
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hideModal"
                class="mt-1 mt-sm-0 mobile-w100"
              >
                取消
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormRadio,
  BTable,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, watch, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import {maxDate} from '@/libs/helper'

import { required } from "@validations";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormRadio,
    BTable,
    BRow,
    BCol,
    BButton,
    BOverlay,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      loading: false,
      tableColumns: [
        // { key: "id", label: "ID "},
        { key: "created_at", label: "修改時間", sortable: true },
        { key: "creator", label: "修改人" },
        { key: "content", label: "修改主題" },
        { key: "changedData", label: "修改內容" },
      ],
      statusOptions: [
        { id: 0, name: "正常" },
        { id: 1, name: "修改" },
        { id: 2, name: "取消" },
      ],
    };
  },
  directives: {
    Ripple,
  },
  props: {
    searchRecordId: {
      type: Number,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    accountData: {
      type: Object,
      required: true,
    },
    accountLogs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("modal-add-account");
    },

    onSubmit() {
      this.$swal({
        title: `你確定嗎？`,
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;

          this.accountNewData = {
            ...this.accountNewData,
            search_record_id: this.searchRecordId,
          };

          let url = "";
          if (this.isEdit) {
            url = "editAccountRecord";
          } else {
            url = "addAccountRecord";
          }
          this.$store
            .dispatch("app/" + url, this.accountNewData)
            .then((response) => {
              this.loading = false;

              this.$swal({
                text: response.data.message,
                confirmButtonText: "確定",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              if (!this.isEdit)
                this.accountNewData = { search_record_id: this.searchRecordId };

              this.$emit("refetch-newest-data", this.searchRecordId);
              this.$emit("refetch-supplier-info");
              this.hideModal();
            })
            .catch((error) => {
              this.loading = false;
              this.hideModal();
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  computed: {},
  setup(props) {
    const accountNewData = ref({});
    const resetAccountData = () => {
      accountNewData = JSON.parse(JSON.stringify({}));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetAccountData);

    const accountOptions = ref([]);

    onMounted(() => {
      refetchOption();
    });

    watch(
      () => props.accountData,
      (newVal, oldVal) => {
        if ( newVal &&!newVal.record_date) {
          accountNewData.value = { ...newVal, record_date: store.state.app.maxDate };
        } else {
          accountNewData.value = { ...newVal };
        }
      }
    );

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          account_option: true,
        })
        .then((response) => {
          accountOptions.value = response.data.account_options;
        });
    };
    return {
      accountNewData,
      accountOptions,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#modal-search-form .modal-dialog {
  width: 100%;
  max-width: 1000px;
}
</style>
