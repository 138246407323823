<template>
  <div class="p-2">
    <modal-PDF
      @refetch-newest-data="triggerModalSearchInfo"
      @refetch-supplier-info="fetchSuppliersInfo"
      :ability="ability"
      :getPdfData="pdfData"
      :number_of_search="number_of_search"
      :number_of_loan="number_of_loan"
    ></modal-PDF>
    <modal-search-form
      :modalTitle="formTitle"
      @refetch-data="refetchData"
      :hkid="hkid"
    ></modal-search-form>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-card>
        <div class="d-flex flex-wrap align-items-center">
        <span class="text-nowrap font-weight-bolder pr-1">做客數值：</span>
        <b-row class="records_count_card flex-grow-1 justify-content-center">
          <div class="info-item mr-3">
            <b class="">搜尋：</b
            ><span> {{ suppliersInfo.search_records_count }}次 </span>
          </div>
          <div class="info-item mr-3">
            <b class="">拒絕紀錄：</b
            ><span>{{ suppliersInfo.reject_records_count }}次</span>
          </div>
          <div class="info-item mr-3">
            <b class="">財務狀況：</b
            ><span>{{ suppliersInfo.client_loans_count }}次</span>
          </div>
          <div class="info-item mr-3">
            <b class="">帳務紀錄：</b
            ><span>{{ suppliersInfo.account_records_count }}次</span>
          </div>
          <div class="info-item mr-3">
            <b class="">無上傳記錄：</b><span>{{ calNoUpload }}次</span>
          </div>
          <!-- <div class="info-item">
            <b class="">上傳率：</b><span>{{ calUploadRate }}%</span>
          </div> -->
          <div class="info-item">
            <b class="">持有合約：</b><span>{{ suppliersInfo.contract_count }}份</span>
          </div>
          
        </b-row>
      </div>
      </b-card>
      <b-card>
        <div class="d-flex flex-wrap flex-sm-nowrap">
          <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
            <!-- <div class="mx-1">
              <font-awesome-icon icon="fa-solid fa-address-card" />
            </div> -->
            <span class="text-nowrap font-weight-bolder pr-1"
              >身分證號碼：</span
            >
            <!-- <div class="d-flex">
              <div
                v-if="
                  this.$store.state.app.user_info &&
                  this.$store.state.app.user_info.info.coin_extra_records
                "
                class="d-inline-flex"
              >
                <span style="white-space: nowrap">半年外個人財務狀況：</span>
                <b-form-checkbox
                  v-model="is_out_of_half_year"
                  class="mr-0"
                  inline
                />
              </div>
              <div
                v-if="
                  this.$store.state.app.user_info &&
                  this.$store.state.app.user_info.info.coin_extra_records
                "
                class="d-inline-flex mx-1"
              >
                <span style="white-space: nowrap">15日以外查詢紀錄：</span>
                <b-form-checkbox
                  v-model="is_out_of_fifteen_days"
                  class="mr-0"
                  inline
                />
              </div>
            </div> -->
          </div>
          <div class="d-flex flex-wrap flex-sm-nowrap w-100">
            <b-form-input
              v-model="searchQuery"
              type="text"
              class="mr-0 mr-sm-2 mt-1 m-sm-0"
              v-on:keyup.enter="triggerSearchConfirm"
              placeholder="輸入身分證號碼 e.g. A123456(7) 或 A1234567"
            ></b-form-input>
            <b-button
              class="bgColor0 text-nowrap mt-1 m-sm-0"
              @click="triggerSearchConfirm"
            >
              <feather-icon size="16" icon="SearchIcon"></feather-icon>
              搜尋
            </b-button>
          </div>
        </div>
      </b-card>
      <div class="d-flex flex-wrap mb-1 justify-content-between">
        <div class="d-flex align-items-center">
          <div>
            <!-- <feather-icon size="16" icon="SearchIcon"></feather-icon> -->
            <span class="title-size">過往搜尋記錄</span>
          </div>
          <div class="form-col-select ml-2">
            <v-select
              style="width: 100px"
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block"
            />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
        </div>
        <!-- <div
          class="d-flex flex-wrap flex-md-nowrap align-items-center mt-1 mt-md-0 max-md-w100"
        >
          <span class="title-size text-nowrap mr-1"
            >身份證號碼(15日內搜尋紀錄)：</span
          >
          <div class="search-primary">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="15日內Free搜尋"
            />
          </div>
        </div> -->
      </div>

      <b-card no-body class="card-statistics p-1 p-sm-0">
        <b-card-body class="">
          <b-table
            ref="refSearchListTable"
            class="position-relative"
            :items="fetchSearchs"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="未找到匹配的記錄"
            :sort-desc.sync="isSortDirDesc"
          >
            <!-- Column: search_key_words -->
            <!-- <template #cell(search_key_words)="data">
              <div class="d-flex align-items-center">
                <span class="mr-1 text-nowrap">
                  <feather-icon
                    class="mr-1"
                    size="16"
                    icon="SearchIcon"
                  ></feather-icon>
                  <span>搜尋：</span>
                </span>

                <span>
                  {{ data.item.search_key_words }}
                </span>
              </div>
            </template> -->

            <!-- Column: create time -->
            <template #cell(created_at)="data">
              {{ getCorrectDateTime(data.item.created_at) }}
            </template>

            <!-- Column: Download -->
            <template #cell(actions)="data">
              <template v-if="!data.item.is_error">
                <a
                  v-if="data.item.showreport"
                  href="javascript:void(0)"
                  @click="triggerModalSearchInfo(data.item.id)"
                >
                  <font-awesome-icon
                    class="color0"
                    style="font-size: 20px"
                    icon="fa-solid fa-file"
                  />
                </a>
                <div v-else>已過期</div>
              </template>
              <template v-else>
                <div
                  class="error-exclamation"
                  v-b-popover.hover.top="data.item.error_message"
                  title="錯誤！"
                >
                  <font-awesome-icon icon="fa-solid fa-exclamation" />
                </div>
              </template>
            </template>
          </b-table>
          <div class="mx-2 my-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">
                  在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至
                  {{ dataMeta.to }} 個記錄</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalSearchs"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BTable,
  BPagination,
  BFormCheckbox,
  BOverlay,
  VBPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import axiosIns from "@/libs/axios";
import ModalPDF from "./modal/ModalPDF";
import ModalSearchForm from "./modal/ModalSearchForm";

import useSearchsList from "./useSearchsList";

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BTable,
    BPagination,
    BFormCheckbox,
    BOverlay,
    vSelect,
    ModalPDF,
    ModalSearchForm,
  },
  directives: {
    "b-popover": VBPopover,
  },
  data() {
    return {
      pdfData: {},
      loading: false,
      // is_detail: 0,
      is_out_of_half_year: 0,
      is_out_of_fifteen_days: 0,
      formTitle: "",
      hkid: "",
      // paySearchQuery: "",
      idError: "",
      number_of_search: 0,
      number_of_loan: 0,
    };
  },

  methods: {
    triggerModalSearchForm() {
      this.$bvModal.show("modal-search-form");
    },
    triggerSearchConfirm() {
      this.loading = true;

      this.IsHKID(this.searchQuery);

      store
        .dispatch("app/checkNumberOfRecord", { q: this.searchQuery })
        .then((response) => {
          this.loading = false;
          let msg = "";
          let tem = this.$store.state.app.user_info.info;
          let coin = Number(tem.coin_per_search);

          // if (this.is_out_of_half_year) {
          //   coin += Number(tem.coin_extra_records);
          // }
          // if (this.is_out_of_fifteen_days) {
          //   coin += Number(tem.coin_extra_search_records);
          // }

          if (response.data.number_of_records >= 1) {
            msg += `系統檢查該身分證號碼已於15天內搜尋
              ${response.data.number_of_records}
              次，請到過往搜尋記錄中取得報告`;
          } else {
            msg += `是否確認使用 ${Number(
              tem.coin_per_search
            )} X Coins 去搜尋資料`;
            // if (this.is_out_of_half_year || this.is_out_of_fifteen_days) {
            //   msg += `，另加信貸報告：`;
            //   if (this.is_out_of_half_year)
            //     msg += `<br/>半年外個人財務狀況 + ${Number(
            //       tem.coin_extra_records
            //     )} X Coins`;

            //   if (this.is_out_of_fifteen_days)
            //     msg += `<br/>15日以外查詢紀錄 + ${Number(
            //       tem.coin_extra_search_records
            //     )} X Coins`;

            //   msg += `<br/>總共：${coin} X Coins`;
            // }
          }

          const isVaild = () => {
            var clientCount = response.data.clientCount;
            var validClientsCount = response.data.validClientsCount;
            var msg2 = "";

            if (clientCount != 0 && validClientsCount / clientCount < 0.3) {
              if (clientCount == 30 || clientCount == 35 || clientCount == 40) {
                msg2 +=
                  "溫馨提示:因上傳率不足而作出提示，為避令你的信貸查詢有誤，請上傳過往的查閱過的信貸紀錄，謝謝。";
              }
              if (clientCount > 50) {
                msg2 +=
                  "溫馨提示:因上傳率不足，你的查詢功能已被停用，請聯絡我們的客服。";
              }

              if (msg2) {
                this.$swal({
                  title: "",
                  width: "670px",
                  html: msg2,
                  showCancelButton: true,
                  confirmButtonText: "確定",
                  cancelButtonText: "取消",
                  showCancelButton: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.value) {
                    if (clientCount <= 50) {
                      this.handleDownload(this.searchQuery, false);
                    }
                  }
                });
              } else {
                if (clientCount <= 50) {
                  this.handleDownload(this.searchQuery, false);
                }
              }
            } else {
              this.handleDownload(this.searchQuery, false);
            }
          };

          if (!this.idError) {
            this.$swal({
              title: "",
              width: response.data.number_of_records >= 1 ? "670px" : "auto",
              html: msg,
              showCancelButton: true,
              confirmButtonText: "確定",
              cancelButtonText: "取消",
              showCancelButton:
                response.data.number_of_records >= 1 ? false : true,
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                if (response.data.number_of_records < 1) {
                  isVaild();
                }
              }
            });
          } else {
            isVaild();
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    handleDownload(key_words) {
      this.loading = true;
      let is_detail = this.is_detail;
      if (isNaN(is_detail)) {
        is_detail ? (is_detail = 1) : (is_detail = 0);
      }
      axiosIns
        .get("/record/search", {
          params: {
            search_record_id: key_words,
            q: key_words,
            all_record_bit: this.is_out_of_half_year,
            all_search_record_bit: this.is_out_of_fifteen_days,
          },
        })
        .then((response) => {
          this.loading = false;
          this.$store.dispatch("app/fetchCoinsBalance");
          this.$bvModal.show("modal-PDF");

          this.$swal({
            text: response.data.message,
            confirmButtonText: "確定",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          // const filepdf = new Blob([response.data], {
          //   type: "application/pdf",
          // });
          // const fileURL = URL.createObjectURL(filepdf);
          // this.pdfData = fileURL;
          this.pdfData = response.data.search_record;

          this.number_of_loan = response.data.number_of_loan;
          this.number_of_search = response.data.number_of_search;
          // if (
          //   this.pdfData.loan_record &&
          //   this.pdfData.loan_record.length == 0
          // ) {
          //   this.$swal({
          //     title: `沒有貸款記錄，你需要新增嗎？`,
          //     showCancelButton: true,
          //     confirmButtonText: "是",
          //     cancelButtonText: "否",
          //     customClass: {
          //       confirmButton: "btn btn-primary",
          //       cancelButton: "btn btn-outline-danger ml-1",
          //     },
          //     buttonsStyling: false,
          //   }).then((result) => {
          //     if (result.value) {
          //       this.$bvModal.show(`modal-loan-edit0`);
          //     }
          //   });
          // }
          this.refetchData();
        })
        .catch(async (error) => {
          this.loading = false;
          let responseObj = error.response.data;

          this.refetchData();
          if (responseObj.client_not_exists) {
            this.formTitle = responseObj.message;
            this.triggerModalSearchForm();
            this.hkid = key_words;
          } else {
            this.$swal({
              text: responseObj.message,
              icon: "error",
              confirmButtonText: "確定",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              showClass: {
                popup: "animate__animated animate__bounceIn",
              },
              buttonsStyling: false,
            });
            this.$bvModal.hide("modal-PDF");
          }
        });
    },
    triggerModalSearchInfo(id) {
      this.loading = true;
      // this.pdfData = item;
      this.$store
        .dispatch("app/fetchNewestDashboardSearchRecord", { id: id })
        .then((response) => {
          this.loading = false;
          this.number_of_loan = response.data.number_of_loan;
          this.number_of_search = response.data.number_of_search;
          this.pdfData = response.data.search_record;
          this.$bvModal.show("modal-PDF");
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },

    IsHKID(str) {
      var strValidChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

      // basic check length
      if (str.length < 8) {
        this.idError = "身份證號碼" + str + "不正確";
        return false;
      }

      // handling bracket
      if (
        str.charAt(str.length - 3) == "(" &&
        str.charAt(str.length - 1) == ")"
      )
        str = str.substring(0, str.length - 3) + str.charAt(str.length - 2);

      // convert to upper case
      str = str.toUpperCase();

      // regular expression to check pattern and split
      var hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;

      var matchArray = str.match(hkidPat);

      // not match, return false
      if (matchArray == null) {
        this.idError = "身份證號碼" + str + "不正確";
        return;
      }

      // the character part, numeric part and check digit part
      var charPart = matchArray[1];
      var numPart = matchArray[2];
      var checkDigit = matchArray[3];

      // calculate the checksum for character part
      var checkSum = 0;
      if (charPart.length == 2) {
        checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
        checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
      } else {
        checkSum += 9 * 36;
        checkSum += 8 * (10 + strValidChars.indexOf(charPart));
      }

      // calculate the checksum for numeric part
      for (var i = 0, j = 7; i < numPart.length; i++, j--)
        checkSum += j * numPart.charAt(i);

      // verify the check digit
      var remaining = checkSum % 11;
      var verify = remaining == 0 ? 0 : 11 - remaining;

      if (verify == checkDigit || (verify == 10 && checkDigit == "A")) {
        this.idError = "";
      } else {
        this.idError = "身份證號碼" + str + "不正確";
      }
    },
  },
  // mounted() {
  //   let router = this.$router
  //   this.$swal({
  //     title: "Some title",
  //     text: "some text for the popup",
  //     type: "warning",
  //     showCancelButton: true,
  //     cancelButtonText: "貸款記錄",
  //   }).then(
  //     function (dismiss) {
  //       if (dismiss.dismiss == "cancel") {
  //         router.push({ name: 'loans-list'})
  //       }
  //     }
  //   );
  // },
  setup() {
    const {
      fetchSearchs,
      tableColumns,
      perPage,
      currentPage,
      totalSearchs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSearchListTable,
      refetchData,
      ability,
      fetchSuppliersInfo,
      suppliersInfo,
      calUploadRate,
      calNoUpload
    } = useSearchsList();

    return {
      // Sidebar
      fetchSearchs,
      tableColumns,
      perPage,
      currentPage,
      totalSearchs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSearchListTable,
      refetchData,
      ability,
      fetchSuppliersInfo,
      suppliersInfo,
      calUploadRate,
      calNoUpload
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.records_count_card{
  .info-item{
    span{
      color:#22c48b
    }
  }
}
</style>
